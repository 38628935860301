import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Form.css';

const Form = ({ setLoading, setSuccess, refreshCalendar }) => {
  const [fullName, setFullName] = useState('');
  const [shiftTime, setShiftTime] = useState('24-Hrs');
  const [shiftDay, setShiftDay] = useState('');
  const [shiftLocation, setShiftLocation] = useState('MCH');
  const [errorMessage, setErrorMessage] = useState('');

  const apiUrl = process.env.REACT_APP_API_URL;

  const currentMonthYear = new Date().toISOString().slice(0, 7); // Get the current month and year in YYYY-MM format
  // const monthYear = '06-2024'; // Fixed month and year

  useEffect(() => {
    // Set the min and max dates to the first and last day of the current month
    const dateInput = document.getElementById('shiftDay');
    dateInput.setAttribute('min', `${currentMonthYear}-01`);
    dateInput.setAttribute('max', `${currentMonthYear}-${new Date(currentMonthYear.split('-')[0], currentMonthYear.split('-')[1], 0).getDate()}`);
  }, [currentMonthYear]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show loading overlay
    setErrorMessage(''); // Clear any previous error messages

    // Convert shift time to array format required by the API
    let shiftTimeArray;
    switch (shiftTime) {
      case '24-Hrs':
        shiftTimeArray = [24, 0];
        break;
      case '12-Hrs':
        shiftTimeArray = [12, 0];
        break;
      case '8-Hrs':
        shiftTimeArray = [8, 0];
        break;
      default:
        shiftTimeArray = [24, 0];
    }

    // Format the date correctly
    const monthYear = `${shiftDay.split('-')[1]}-${shiftDay.split('-')[0]}`; // Convert from YYYY-MM-DD to MM-YYYY
    const formattedDate = `${shiftDay.split('-')[2]}-${monthYear}`;

    const payload = {
      name: fullName,
      shift_time: shiftTimeArray,
      shift_date: formattedDate,
      location: shiftLocation,
    };

    try {

      const response = await axios.post(`${apiUrl}/shifts`, payload);
      console.log('Shift added successfully:', response.data);
      setLoading(false); // Hide loading overlay
      setSuccess(true); // Show success notification
      setTimeout(() => setSuccess(false), 3000); // Hide success notification after 3 seconds
      refreshCalendar(); // Refresh calendar events
    }  catch (error) {
      if (error.response && error.response.status === 409) {
        setErrorMessage(error.response.data.message); // Show conflict error message
      } else {
        console.error('Error adding shift:', error);
      }
      setLoading(false); // Hide loading overlay in case of error
    }
  };

  return (
    <div className="form-container">
      <h2 className="form-title">Shift Details</h2>
      <form className="shift-form" onSubmit={handleSubmit}>
        <div>
          <label>Full Name:</label>
          <input 
            type="text" 
            value={fullName} 
            onChange={(e) => setFullName(e.target.value)} 
          />
        </div>
        <div>
          <label>Shift Time:</label>
          <select 
            value={shiftTime} 
            onChange={(e) => setShiftTime(e.target.value)}
          >
            <option value="24-Hrs">24 Hrs</option>
            <option value="12-Hrs" disabled>12 Hrs</option>
            <option value="8-Hrs" disabled>8 Hrs</option>
          </select>
        </div>
        <div>
          <label>Shift Day:</label>
          <input 
            type="date" 
            id="shiftDay"
            value={shiftDay} 
            onChange={(e) => setShiftDay(e.target.value)} 
            required
          />
        </div>
        <div>
          <label>Shift Location:</label>
          <select 
            value={shiftLocation} 
            onChange={(e) => setShiftLocation(e.target.value)}
          >
            <option value="MCH">MCH</option>
            <option value="MDH">MDH</option>
            <option value="BAH">BAH</option>
            <option value="MPH">MPH</option>
          </select>
        </div>
        <button type="submit" className="submit-button">Submit</button>
      </form>
      {errorMessage && (
        <div className="error-notification">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default Form;