import React, { useState, useRef } from 'react';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Calendar from './components/Calendar/Calendar';
import Form from './components/Form/Form';
import './App.css';

const App = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false); // State for success notification
  const calendarRef = useRef(null);

  const handleAddEvent = (newEvent) => {
    setEvents([...events, newEvent]);
  };

  const handleDateClick = (arg) => {
    // Example of handling date click, you might want to open a modal or form here
    console.log(arg);
  };

  const refreshCalendar = () => {
    if (calendarRef.current) {
      calendarRef.current.refreshEvents();
    }
  };

  return (
    <div className="app">
      {loading && <div className="loading-overlay">Processing...</div>}
      <Header />
      <div className="main-content">
        <Calendar ref={calendarRef} events={events} handleDateClick={handleDateClick} />
        <Form 
          onAddEvent={handleAddEvent} 
          setLoading={setLoading} 
          setSuccess={setSuccess} 
          refreshCalendar={refreshCalendar}
        />
      </div>
      <Footer />
      {success && <div className="success-notification">Submitted successfully!</div>}
    </div>
  );
};

export default App;
