import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import './EventPopup.css';

const EventPopup = ({ event, referenceElement, onClose }) => {
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [{ name: 'offset', options: { offset: [0, 10] } }],
  });

  return (
    <>
      {event && (
        <div ref={setPopperElement} style={styles.popper} {...attributes.popper} className="popover-content">
          <div style={{ backgroundColor: 'white', border: '1px solid black', padding: '10px', zIndex: 1000 }}>
            <h3>{event.title}</h3>
            <p><strong>Assigned location:</strong> {event.extendedProps.location}</p>
            {event.extendedProps.conflict_with.length > 0 && (
              <>
                <p><strong>Conflict with:</strong> {event.extendedProps.conflict_with.join(', ')}</p>
                <p><strong>Conflict at:</strong> {event.extendedProps.conflict_at.join(', ')}</p>
              </>
            )}
            <button className='close-button' onClick={onClose}>Close</button>
          </div>
        </div>
      )}
    </>
  );
};

export default EventPopup;
