import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import axios from 'axios';
import EventPopup from './EventPopup';
import './Calendar.css';

const Calendar = forwardRef((props, ref) => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [referenceElement, setReferenceElement] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchEvents = async () => {
    try {
      const response = await axios.get(`${apiUrl}/processedresult`);
      const eventData = response.data.map(event => ({
        id: event.uid,
        title: event.name,
        start: new Date(event.shift_year, event.shift_month - 1, event.shift_day),
        className: event.conflict ? 'conflict' : 'no-conflict',
        extendedProps: {
          location: event.location,
          conflict_with: event.conflict_with,
          conflict_at: event.conflict_at
        }
      }));
      setEvents(eventData);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  useImperativeHandle(ref, () => ({
    refreshEvents: fetchEvents,
  }));

  const handleEventClick = (eventInfo) => {
    setSelectedEvent(eventInfo.event);
    setReferenceElement(eventInfo.el);
  };

  const closePopup = () => {
    setSelectedEvent(null);
    setReferenceElement(null);
  };

  const renderEventContent = (eventInfo) => {
    return (
      <div className="fc-event-custom">
        <div className='fc-daygrid-event-dot'></div>
        <div className="fc-event-title">{eventInfo.event.title}</div>
      </div>
    );
  };

  return (
    <div className="calendar-container">
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        events={events}
        eventClick={handleEventClick}
        eventContent={renderEventContent}
        headerToolbar={{
          left: '',
          center: 'title',
          right: ''
        }}
      />
      {selectedEvent && (
        <EventPopup
          event={selectedEvent}
          referenceElement={referenceElement}
          onClose={closePopup}
        />
      )}
    </div>
  );
});

export default Calendar;